import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import About from "./About";
import Videos from "./Videos";

import * as serviceWorker from "./serviceWorker";
import { HashRouter, Routes, Route } from "react-router-dom";
import Header from "./Header";

ReactDOM.render(
    <HashRouter>
        <Header></Header>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/about" element={<About />} />
            <Route path="/videos" element={<Videos />} />
        </Routes>
    </HashRouter>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
