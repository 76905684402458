import React from "react";
import "./About.css";

import wesHowellIcon from "./icons/WesHowell_Logo_BWSmall_NoText.png";

function About() {
    return (
        <div className="about-app">
            <div className="about-container">
                <div className="about-card">
                    <div className="about-card-text">
                        <h3>About</h3>
                        <p>
                            This is a place to store some of my technical and
                            creative explorations.
                            <br />
                            <br />A work in progress.
                            <br />
                            <br />I am a filmmaker, an editor, and a
                            photographer. I am currently working for the
                            Premiere Pro Engineering team at Adobe. When not
                            working on software, I'm usually out playing with
                            cars or working on various creative pursuits.
                            <h4>
                                Reach out at{" "}
                                <a
                                    href="mailto:wes@weshowell.com"
                                    style={{ textDecoration: "underline" }}
                                >
                                    Wes@WesHowell.com
                                </a>
                            </h4>
                        </p>
                    </div>
                    {/* <div className="about-card-footer"></div> */}
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "flex-end",
                    height: "300px",
                    marginBottom: "100px",
                    marginTop: "2em",
                }}
            >
                <img src={wesHowellIcon} className="wes-howell-icon" />
            </div>
        </div>
    );
}

export default About;
