import React from "react";
import { slide as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";

// var styles = {
//     bmBurgerButton: {
//         position: "fixed",
//         width: "36px",
//         height: "30px",
//         right: "10px",
//         top: "10px",
//     },
//     bmBurgerBars: {
//         background: "#fff",
//         height: "1px",
//     },
//     bmBurgerBarsHover: {
//         background: "#a90000",
//     },
//     bmCrossButton: {
//         height: "24px",
//         width: "24px",
//     },
//     bmCross: {
//         background: "#bdc3c7",
//     },
//     bmMenuWrap: {
//         position: "fixed",
//         height: "100%",
//     },
//     bmMenu: {
//         background: "#373a47",
//         padding: "2.5em 1.5em 0",
//         fontSize: "1.15em",
//     },
//     bmMorphShape: {
//         fill: "#373a47",
//     },
//     bmItemList: {
//         color: "#b8b7ad",
//         padding: "0.8em",
//     },
//     bmItem: {
//         display: "inline-block",
//     },
//     bmOverlay: {
//         background: "rgba(0, 0, 0, 0.3)",
//     },
// };

const Header = () => {
    return (
        <div className="app-header">
            <h1 className="title-header">
                <NavLink to="/">WES HOWELL</NavLink>
            </h1>

            <div className="mobile-header-nav-links">
                <Menu right={true}>
                    <NavLink id="home" className="menu-item" to="/">
                        Home
                    </NavLink>
                    <NavLink id="about" className="menu-item" to="/about">
                        About
                    </NavLink>
                    <NavLink id="videos" className="menu-item" to="/videos">
                        Videos
                    </NavLink>
                </Menu>
            </div>
            <div className="header-nav-links">
                <div className="nav-link-button">
                    <NavLink to="/videos">VIDEOS</NavLink>
                </div>
                <div className="nav-link-button">
                    <NavLink to="/about">ABOUT</NavLink>
                </div>
            </div>
        </div>
    );
};

export default Header;
