import React from "react";
import "./About.css";

const videoList = [
    {
        title: "Intro",
        url: "https://www.youtube.com/embed/7ao7QO7ZzUk",
    },
    {
        title: "Editing in the Timeline",
        url: "https://www.youtube.com/embed/2hNK_lLCf-w",
    },
    {
        title: "Timeline Tips & Tricks",
        url: "https://www.youtube.com/embed/PACtEVKMiYs",
    },
    {
        title: "Source Monitor Workflows",
        url: "https://www.youtube.com/embed/PvNiFd_APbk",
    },
    {
        title: "Next Level Trimming",
        url: "https://www.youtube.com/embed/3iLNEg-wlZI",
    },
    {
        title: "Pancake Timeline Workflows",
        url: "https://www.youtube.com/embed/gu68hKVEjOE",
    },
];

function Videos() {
    return (
        <div className="about-app">
            <div className="videos-container">
                <div className="about-card">
                    <div className="about-card-text">
                        <h3>Premiere Pro Tutorials</h3>
                        <p>
                            As an editor and post production expert, I've
                            compiled my favorite workflow tips and tricks into a
                            series of videos. It's my hope that these will be
                            most useful to serious editors that are looking to
                            get the most out of Premiere Pro.
                        </p>
                    </div>
                    {/* <div className="about-card-footer"></div> */}
                </div>
                <div className="videos-flex-box">
                    {videoList.map((video) => {
                        return (
                            <>
                                <div class="video-container">
                                    {/* <h3>{video.title}</h3> */}
                                    <iframe
                                        class="video-container-iframe"
                                        src={
                                            video.url +
                                            "?modestbranding=1&rel=0"
                                        }
                                        title={video.title}
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                    />
                                </div>
                                <p
                                    style={{
                                        paddingBottom: "50px",
                                        textAlign: "center",
                                    }}
                                >
                                    {video.description}
                                </p>
                            </>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default Videos;
