import React from "react";

import "./flickity.css";
import Flickity from "react-flickity-component";

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
        let newItem = item.replace("./", "");
        let newItemContents = newItem.split("/");
        images[newItemContents[0]] = {
            ...images[newItemContents[0]],
            [newItemContents[1]]: r(item),
        };
    });
    return images;
}

const flickityOptions = {
    initialIndex: 0,
    freeScroll: true,
    percentPosition: false,
    prevNextButtons: true,
    pageDots: true,
    setGallerySize: true,
    wrapAround: false,
    contain: true,
    fullscreen: true,
    fade: true,
};

const galleryOne = importAll(
    require.context("./gallery-images/Gallery1", true)
);
const galleryKeysSorted = Object.keys(galleryOne).sort((a, b) => {
    console.log(a);
    // Sort by number stored in name in style: num_name
    const numOne = parseInt(a.split("_")[0]);
    const numTwo = parseInt(b.split("_")[0]);
    return numOne - numTwo;
});

const Dashboard = () => {
    return (
        <div className="fullscreen-gallery">
            {galleryKeysSorted.map((imageSet) => {
                return (
                    <Flickity
                        className={"carousel"} // default ''
                        elementType={"div"} // default 'div'
                        options={flickityOptions} // takes flickity options {}
                        disableImagesLoaded={false} // default false
                        reloadOnUpdate // default false
                        static // default false
                    >
                        {Object.keys(galleryOne[imageSet]).map((image) => {
                            return (
                                <img
                                    src={galleryOne[imageSet][image]}
                                    className="fullscreen-image"
                                />
                            );
                        })}
                    </Flickity>
                );
            })}
        </div>
    );
};

export default Dashboard;
